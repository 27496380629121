<template>
  <div>
    <!-- Бүх хуудсанд ашиглагдах header section -->
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Бүтээгдэхүүний жагсаалт</h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
              <el-button v-if="isExcel" icon="el-icon-download" type="file" size="small" @click="getExelDownloads()">Татаж авах (.xlsx)
              </el-button>
              <router-link to="/add-product"  >
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabName" @tab-click="onClickTab">
        <el-tab-pane v-for="(category) in tableCategories" :key="category.category_id" :label="category.name_mon + ' (' + category.product_count + ')'" :name="category.category_id">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="sortflex">
                <el-col :span="8">
                  <el-input v-model="search" @change="onSearch" placeholder="Бараа хайх" class="input-with-select">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <el-select v-model="selectedSuppliers" @change="changeSupplierSelect" @remove-tag="removeTagFillter" placeholder="Нийлүүлэгч сонгох" multiple filterable>
                     <el-option
                      v-for="(supplier, index1) in suppliers"
                      :key="index1"
                      :label="supplier.supplier_monName"
                      :value="supplier.supplier_id"
                      >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4">
                   <el-select v-model="selectedBrands" @change="changeSupplierSelect" @remove-tag="removeTagFillter" placeholder="Брэнд сонгох" multiple filterable>
                     <el-option
                      v-for="(brand, index2) in brandList"
                      :key="index2"
                      :label="brand.brand_monName"
                      :value="brand.brand_id"
                      >
                    </el-option>
                  </el-select>
                </el-col>
              <el-col :span="4">
                   <el-select v-model="selectStatus" @change="filterStatus" placeholder="Төлөв сонгох">
                    <el-option
                      v-for="(status, index3) in statusSelect"
                      :key="index3"
                      :label="status.label"
                      :value="status.value"
                      >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="4" class="text-right">
                  <el-dropdown @command="handleSort">
                    <el-button type="default">{{sortName}} <i class="el-icon-sort"></i></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in dropdownDataFilter" :key="indexFilter">{{filter.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <!-- Product list table ued zaaval product-table gesen class uguh -->
            <el-table
              v-loading="isLoading"
              class="product-table"
              :data="tableData"
              @cell-click="onPressItem"
              @sort-change='handleSortTable'
              :row-class-name="productClassName"
              style="width: 100%">
              <el-table-column
                prop="image"
                label="Бүтээгдэхүүн"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-row :gutter="10" align="middle" type="flex">
                     <el-col :span="6">
                        <el-image
                          class="image-holder"
                          :src="Array.isArray(scope.row.image) ? scope.row.image[0] : scope.row.image"
                          fit="contain"></el-image>
                    </el-col>
                    <el-col :span="18"><strong>{{scope.row.name_mon}}</strong></el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                prop="inventory"
                label="Агуулах">
                <template slot-scope="scope">
                  {{scope.row.inventoryCount}} сон -
                  <span class="boxed-text danger" v-if="scope.row.inventoryBalance === null || scope.row.inventoryBalance === 0">0</span>
                  <span class="boxed-text warning" v-else-if="scope.row.inventoryBalance < 3">{{scope.row.inventoryBalance}}</span>
                  <span class="boxed-text" v-else>{{scope.row.inventoryBalance}}</span>
                  ш
                </template>
              </el-table-column>
              <el-table-column
                prop="price"
                label="Үнэ"
                sortable
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <el-col :span="18">{{generateCurrency(scope.row.price)}}</el-col>
                </template>
              </el-table-column>
              <el-table-column
                prop="categorName"
                label="Категори"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span>{{generateCategoryName(scope.row.categorName)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="status"
                label="Төлөв">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status === 'active'">Идэвхитэй</el-tag>
                  <el-tag v-else-if="scope.row.status === 'inactive'" type="info">Идэвхигүй</el-tag>
                  <el-tag v-else-if="scope.row.status === 'brand_inactive'" type="info">Брэнд хаалттай</el-tag>
                  <el-tag v-else type="warning">Үнэ батлуулаагүй</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="brandName"
                label="Брэнд/Нийлүүлэгч"
                :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span>{{scope.row.brandName}}/{{scope.row.supplierName}}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="text-right mt10"
              background
              :page-size="pageSize"
              :current-page.sync="currentPage"
              :page-sizes="[5, 20, 50, 100]"
              layout=" total, sizes, prev, pager, next"
              @size-change="sizeChangeHandler"
              @current-change="curentPageHandler"
              :total="totalCount">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <loader :active="isUploading"/>
    </div>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import helper from '../../../helpers/helper'
import loader from '../../../components/common/loader'
// import XLSX from 'xlsx'

export default {
  name: 'ProductList',

  components: {
    loader
  },

  data () {
    return {
      categoryId: '',
      isExcel: 'all',
      isUploading: false,
      brandList: [],
      suppliers: [],
      downloadXlsx: '',
      selectedBrands: [],
      selectedSuppliers: [],
      selectStatus: '',
      asd: '',
      sortName: 'Эрэмбэлэх',
      field: '',
      sort: '',
      tableCategories: [],
      totalCountCategories: 0,
      totalCount: 0,
      pageSize: 20,
      pageFrom: 0,
      currentPage: 1,
      tableData: null,
      backupData: null,
      isLoading: false,
      search: '',
      activeTabName: 'all',
      statusFilter: 'all',
      statusSelect: [{
        value: '',
        label: 'Бүгд'
      }, {
        value: 'active',
        label: 'Идэвхтэй'
      }, {
        value: 'inactive',
        label: 'Идэвхгүй'
      }, {
        value: 'brand_inactive',
        label: 'Брэнд хаалттай'
      }, {
        value: 'price_inactive',
        label: 'Үнэ батлуулаагүй'
      }],
      dropdownDataFilter: [
        {
          label: 'Бүгд',
          value: 'all'
        }, {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }, {
          label: 'Агуулахад байхгүй ч зарах',
          value: 'sellCheck'
        }
      ]
    }
  },

  mounted () {
    this.excelButton()
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingTab = this.$route.query.tab
      const incomingField = this.$route.query.field
      const incomingSort = this.$route.query.sort
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.activeTabName = incomingTab
      this.search = incomingSearch
      this.field = incomingField
      this.sort = incomingSort
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    if (this.$route.params.supplierSelect !== undefined && this.$route.params.supplierSelect.length !== 0 | this.$route.params.brandSelect !== undefined && this.$route.params.brandSelect.length !== 0) {
      this.selectedSuppliers = this.$route.params.supplierSelect
      this.selectedBrands = this.$route.params.brandSelect
      this.getProducts(from, size, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    } else {
      this.getProducts(from, size, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    }
    this.getSuppliers(0, 1000, this.search, this.field, this.sort)
    this.getBrands(0, 1000, this.search, this.field, this.sort)
  },

  methods: {
    getSuppliers (from, size, search, field, sort) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&field=' + field + '&sort=' + sort
      services.getSuppliers(query).then(response => {
        this.suppliers = response.datas
      })
    },

    getBrands (from, size, search, field, sort) {
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&field=' + field + '&sort=' + sort
      services.getBrands(query).then(data => {
        this.brandList = data.datas
      })
    },

    removeTagFillter () {
      if (this.selectedSuppliers.length !== 0) {
        this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
      } else if (this.selectedBrands.length !== 0) {
        this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
      } else {
        this.getProducts(this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
      }
    },

    changeSupplierSelect () {
      const body = {
        supplier: this.selectedSuppliers
      }
      this.isLoading = true
      services.getBrandsBySupplierIds(body).then(response => {
        if (body.supplier.length > 0 && response.status === 'success') {
          this.brandList = response.data
          this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
        } else {
          this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
        }
        if (this.selectedSuppliers.length === 0) {
          this.getBrands(0, 1000, this.search, this.field, this.sort)
        }
        this.isLoading = false
      })
    },

    filterStatus () {
      this.currentPage = 1
      this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    },

    onSearch () {
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabName, field: this.field, sort: this.sort } }).catch(() => {})
      this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    },

    generateCategoryName (categoryName) {
      let tempCategoryName = ''
      categoryName.forEach((element, index) => {
        tempCategoryName = tempCategoryName + (index === 0 ? '' : ', ') + element
      })
      return tempCategoryName
    },
    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabName, field: this.field, sort: this.sort } }).catch(() => {})
      this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    },
    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabName, field: this.field, sort: this.sort } }).catch(() => {})
      this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    },

    productClassName ({ row }) {
      if (row.status === 'active') {
        return 'success-row'
      } else if (row.status === 'inactive') {
        return 'warning-row'
      }
    },

    async excelButton (data) {
      if (await (data && data.name !== 'all') || this.activeTabName !== 'all') {
        this.isExcel = true
      } else {
        this.isExcel = false
      }
    },

    onClickTab (data) {
      this.currentPage = 1
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabName, field: this.field, sort: this.sort } }).catch(() => {})
      this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
      this.excelButton(data)
      this.categoryId = data.name
    },

    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },

    handleSort (data) {
      const filters = {
        newest: {
          field: 'created_at',
          sort: 'desc',
          sortName: 'Шинэ эхэнд'
        },
        oldest: {
          field: 'created_at',
          sort: 'asc',
          sortName: 'Хуучин эхэнд'
        },
        firstChar: {
          field: 'name_mon',
          sort: 'asc',
          sortName: 'Нэр А - Я'
        },
        lastChar: {
          field: 'name_mon',
          sort: 'desc',
          sortName: 'Нэр Я - А'
        },
        all: {
          field: '',
          sort: '',
          sortName: 'Эрэмбэлэх'
        },
        sellCheck: {
          field: 'sell_check',
          sort: 'true',
          sortName: 'Агуулахад байхгүй ч зарах'
        }
      }
      const currentFilter = JSON.parse(JSON.stringify(filters[data]))
      this.field = currentFilter.field
      this.sort = currentFilter.sort
      this.sortName = currentFilter.sortName
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabName, field: this.field, sort: this.sort } }).catch(() => {})
      this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    },

    getExelDownloads () {
      const body = {
        category_id: this.categoryId
      }
      services.getExelDownload(body).then(response => {
        if (response.status === 'success') {
          this.downloadXlsx = response.data
          window.open(this.downloadXlsx)
        } else {
          this.notify('Уучлаарай', response, 'error')
        }
      })
    },

    handleSortTable (data) {
      if (data.column.label === 'Бүтээгдэхүүн' && data.order === 'ascending') {
        this.field = 'name_mon'
        this.sort = 'asc'
      } else if (data.column.label === 'Бүтээгдэхүүн' && data.order === 'descending') {
        this.field = 'name_mon'
        this.sort = 'desc'
      } else if (data.column.label === 'Агуулах' && data.order === 'ascending') {
        this.field = 'count'
        this.sort = 'asc'
      } else if (data.column.label === 'Агуулах' && data.order === 'descending') {
        this.field = 'count'
        this.sort = 'desc'
      } else if (data.column.label === 'Үнэ' && data.order === 'ascending') {
        this.field = 'price'
        this.sort = 'asc'
      } else if (data.column.label === 'Үнэ' && data.order === 'descending') {
        this.field = 'price'
        this.sort = 'desc'
      }
      this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabName, field: this.field, sort: this.sort } }).catch(() => {})
      this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, this.search, this.activeTabName === 'all' ? '' : this.activeTabName, this.field, this.sort)
    },

    onPressItem (item) {
      this.$router.push({ name: 'updateProduct', params: { product_id: item.productId, tempSuppliers: this.selectedSuppliers, tempBrands: this.selectedBrands }, query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabName, field: this.field, sort: this.sort } }).catch(() => {})
    },

    getProducts (from, size, search, categoryId, field, sort) {
      const body = {
        supplier: this.selectedSuppliers,
        brand: this.selectedBrands
      }
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size + '&category_id=' + categoryId + '&field=' + field + '&sort=' + sort + '&status=' + this.selectStatus
      this.isLoading = true
      this.isUploading = true
      services.getAllProducts(body, query).then(response => {
        if (response.datas && response.status === 'success') {
          var tempProducts = JSON.parse(JSON.stringify(response.datas))
          tempProducts.forEach(element => {
            element.package_image = element.package_image ? element.package_image : []
          })
          this.tableData = response.datas
          this.totalCount = response.total
          const tempCategories = [{
            category_id: 'all',
            name_mon: 'Бүгд',
            product_count: response.total
          }]
          response.categories.forEach(element => {
            tempCategories.push(element)
          })
          this.tableCategories = tempCategories
          this.isLoading = false
          this.isUploading = false
        } else if (search && search !== '') {
          this.search = ''
          this.$router.push({ name: 'products', query: { page: this.currentPage, size: this.pageSize, search: '', tab: this.activeTabName } }).catch(() => {})
          this.notify('Уучлаарай', 'Бүтээгдэхүүн байхгүй байна', 'error')
          this.getProducts(this.pageSize * (this.currentPage - 1), this.pageSize, '', this.activeTabName === 'all' ? '' : this.activeTabName)
          this.isLoading = false
        }
        this.isLoading = false
      })
    },

    notify (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>

<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
